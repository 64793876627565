import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import BespokeLogo from "../../assets/bespoke_footer_icon.png";
import './style.css';
import { FaInstagram, FaTripadvisor, FaFacebookSquare } from 'react-icons/fa';
import { useImageViewerContext } from 'context/imageViewer';
import Modal from 'component/Modal';
import { Img } from 'component/FrameL';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';

const Footer = ({ coreData }) => {
    const { address, phoneNumber, email, facebook, instagram, tripAdvisor } = coreData;
    const { isOpen, closeImage, nextImage, previousImage, activeImage } = useImageViewerContext();

    return <>
        <div className="footer__container relative bg-black px-4 md:px-8 flex flex-row flex-wrap justify-between text-lg text-white items-center z-0">
            <div className="stack-l w-1/2 md:w-4/12 text-center md:text-left ">
                <ReactMarkdown>{address}</ReactMarkdown>
                <div>
                    <a className='block' href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    <a className='block' href={`mailto:${email}`}>{email}</a>
                </div>
            </div>
            <div className="stack-l w-1/2 md:w-4/12 text-center items-center" style={{ "--space": "var(--s-3)" }}>
                <Link to="/policies">
                    Policies
                </Link>

                <div className="cluster-l text-4xl" style={{
                    "--cSpace": "var(--s-1)"
                }}>
                    <a href={tripAdvisor} target="_blank" aria-label="Trip Advisor Link">
                        <FaTripadvisor></FaTripadvisor>
                    </a>
                    <a href={facebook} target="_blank" aria-label="Facebook Link">
                        <FaFacebookSquare></FaFacebookSquare>
                    </a>
                    <a href={instagram} target="_blank" aria-label="Instagram Link">
                        <FaInstagram></FaInstagram>
                    </a>
                </div>
            </div>
            <div className="stack-l w-full md:w-4/12">
                <div className="flex flex-col items-center md:items-end">
                    <a target='_blank' href="https://www.bespoke.house/">
                        <Img src={BespokeLogo} isExternal={false} alt="Bespoke Logo" />
                    </a>
                </div>
            </div>
        </div>
        <Modal isOpen={isOpen} onClose={closeImage} className="no-animate bg-black bg-opacity-80" closeIconClass="m-2">
            <div className="">
                <Img className="mx-auto p-4 md:p-8 lg:p-12 max-h-screen max-w-full" src={activeImage} onClick={nextImage} />
                <button className="absolute cursor-pointer top-50p left-0" onClick={previousImage}>
                    <IoIosArrowDropleftCircle className="text-white opacity-80 text-4xl m-4"></IoIosArrowDropleftCircle>
                </button>
                <button className="absolute cursor-pointer top-50p right-0" onClick={nextImage}>
                    <IoIosArrowDroprightCircle className="text-white opacity-80 text-4xl m-4"></IoIosArrowDroprightCircle>
                </button>
            </div>
        </Modal>
    </>;
};

export default Footer;

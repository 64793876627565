import React, { Suspense, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';

const HomePage = React.lazy(() => import('./container/Home'));
const RoomDetailPage = React.lazy(() => import('./component/RoomDetail'));
const LocationPage = React.lazy(() => import('./container/Location'));
const ContactPage = React.lazy(() => import('./container/Contact'));
const PolicyPage = React.lazy(() => import('./container/Policy'));
const InsidersGuidePage = React.lazy(() => import('./container/InsidersGuide'));
// const GroupContactForm = React.lazy(() => import('./component/ContactForm'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
        <Switch>
          <Route path="/rooms/:slug" component={() => <RoomDetailPage />} />
          <Route path="/location" component={() => <LocationPage />} />
          <Route path="/contact-us" component={() => <ContactPage />} />
          <Route path="/policies" component={() => <PolicyPage />} />
          <Route path="/insiders-guide" component={() => <InsidersGuidePage />} />
          <Route exact path="/" component={() => <HomePage />} />
          <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route>
        </Switch>
        <Footer coreData={coreData} />
      </div>
    </Suspense>
  );
}


export default CmsDataWrapper(App, ['core-data']);
